@charset "UTF-8";

@font-face {
  font-family: "Gmarket Sans";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gmarket Sans";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gmarket Sans";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}

:root {
  --primary: #555555;
  --highlight: #84a0e8;
  --dark: #222222;
  --darkgray: #333333;
  --gray: #707070;
  --date: #999999;
  --border: #dfdfdf;
  --placeholder: #acacac;
  --green: #15b58f;
  --orange: #ffc149;
  --red: #fe5f75;
  --babyblue: #bbe7fe;
  --lilac: #d3b5e5;
  --beige: #f4edd3;
  --vh: 100vh;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  width: 100%;
  height: 100%;
}

html,
input,
select,
textarea,
button {
  font-size: 16px;
  line-height: 1.2;
  font-family: "Pretendard", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-weight: 400;
  color: var(--primary);
}

body {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
} /* 뷰포트 변환시 폰트 크기 자동 확대 방지*/

fieldset,
img {
  border: 0 none;
}

dl,
ul,
ol,
menu,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

input,
select,
textarea,
button {
  vertical-align: middle;
  outline: none;
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="email"],
input[type="tel"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

input[type="checkbox"]:checked {
  background-color: var(--gray);
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"] {
  -webkit-appearance: button;
  appearance: button;
  border-radius: 0;
  border-width: 0;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

a {
  color: var(--darkgray);
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover {
  text-decoration: none;
}

address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}

/* Chrome */
input[type="range"] {
  width: 100px;
  cursor: pointer;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border-color: transparent;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}

/* IE */
input[type="range"]::-ms-track {
  width: 100px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (max-width:1280px) {
  html,
  input,
  select,
  textarea,
  button {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  html,
  input,
  select,
  textarea,
  button {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  html,
  input,
  select,
  textarea,
  button {
    font-size: 12px;
  }
}
